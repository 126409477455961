var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: {
        bottom: "",
        location: "auto",
        opacity: "1",
        disabled: _vm.disabled,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "span",
                  _vm._g(_vm._b({}, "span", attrs, false), on),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      !_vm.largeTooltip
        ? _c(
            "span",
            { staticClass: "icon-tooltip-container tooltip-multiline" },
            [_vm._v(" " + _vm._s(_vm.tooltipText) + " ")]
          )
        : _c("span", [
            _c("div", { staticClass: "large-tooltip-container" }, [
              _c(
                "div",
                { staticClass: "large-tooltip-title" },
                [
                  _c(
                    "v-icon",
                    {
                      class: _vm.largeTooltipIconClass,
                      attrs: { color: "#ffffff" },
                    },
                    [_vm._v(_vm._s(_vm.largeTooltipIcon))]
                  ),
                  _vm._v(" " + _vm._s(_vm.largeTooltipTitle) + " "),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "large-tooltip-content" },
                [_vm._t("largeTooltipContent")],
                2
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-tooltip bottom location="auto" opacity="1" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <span v-if="!largeTooltip" class="icon-tooltip-container tooltip-multiline">
      {{ tooltipText }}
    </span>

    <span v-else>
      <div class="large-tooltip-container">
        <div class="large-tooltip-title">
          <v-icon :class="largeTooltipIconClass" color="#ffffff">{{ largeTooltipIcon }}</v-icon> {{ largeTooltipTitle }}
        </div>
        <div class="large-tooltip-content">
          <slot name="largeTooltipContent"></slot>
        </div>
      </div>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AGTooltip',
  props: {
    tooltipText: {
      type: String,
      required: false,
    },

    largeTooltip: {
      type: Boolean,
      default: false,
    },

    largeTooltipTitle: {
      type: String,
      required: false,
    },

    largeTooltipIcon: {
      type: String,
      required: false,
    },

    largeTooltipIconClass: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import './src/design/components/tooltip.scss';
</style>
